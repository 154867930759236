import type { GetStaticProps, InferGetStaticPropsType } from 'next'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { Button } from '../components/Button'
import { TranslationsContextProvider, useTranslate } from '../components/contexts/TranslationsContextProvider'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import styles from './404.module.sass'
import { getStaticProps as getPageStaticProps } from './[[...path]]'

export const getStaticProps: GetStaticProps = async (context) => {
	const props = await getPageStaticProps(context)

	if ('props' in props) {
		return {
			props: {
				...props.props,
				page: null,
			},
		}
	}

	return props
}

const NotFoundInner = () => {
	const translation = useTranslate()
	return (
		<div className={styles.content}>
			<h1 className={styles.title}>404</h1>
			<h2 className={styles.subtitle}>{translation('notFound.title')}</h2>
			<p className={styles.text}>{translation('notFound.text')}</p>
			<div className={styles.actions}>
				<Link href="/" passHref>
					<Button type="button" size="small">
						{translation('notFound.button')}
					</Button>
				</Link>
			</div>
		</div>
	)
}
const NotFound: FunctionComponent<InferGetStaticPropsType<typeof getStaticProps>> = ({
	header,
	footer,
	secondaryFooter,
	siteCode,
	magazineHeader,
	translations,
}) => {
	return (
		<TranslationsContextProvider data={translations}>
			<Header {...header} magazineHeader={magazineHeader} />
			<main>
				<div className={styles.wrapper}>
					<NotFoundInner />
				</div>
			</main>
			<Footer {...footer} secondaryFooter={secondaryFooter} siteCode={siteCode} />
		</TranslationsContextProvider>
	)
}

export default NotFound
